import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import { Helmet, HelmetProvider } from "react-helmet-async";

import { UserProvider } from './components/UserProvider.js';
import { PermissionsProvider } from './components/hasPermissions.js';

import Header from './layout/Header';
import Footer from './layout/Footer';

import Home from './tabs/Home';

import Ciudadanos from './tabs/Ciudadanos.js';
import Combustible from './tabs/Combustible.js';
import Compras from './tabs/Compras.js';
import Rentas from './tabs/Rentas.js';
import Hacienda from './tabs/Hacienda.js';
import Usuarios from './tabs/Usuarios.js';
import Comercios from './tabs/Comercios.js';

import SMSSender from './components/SMSSender.js';

import Groups from './tabs/Groups.js';
import RolesOverview from './tabs/roles/Overview.js';

import Maintenance from './warns/Maintenance.js';
import NotFound from './warns/NotFound.js';

/* PDF GENERATOR */
import PDFCombustible from './tabs/pdf/Combustible.js';
import PDFCompra from './tabs/pdf/Compra.js';
import PDFRentas from './tabs/pdf/Rentas.js';
import PDFHacienda from './tabs/pdf/Hacienda.js';

/* SCANNERS */
import SCDocumentos from './tabs/scanner/Documento.js';
import PDF417 from './tabs/scanner/PDF417.js';

/* CAMERA */
import CADocumentos from './tabs/camera/Documento.js';
import CAObtener from './tabs/camera/Obtener.js';
import CASkeleton from './tabs/camera/Skeleton.js';

function AnimatedRoutes({ configuration, apiURL }) {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home config={configuration} apiURL={apiURL} />} />
        <Route path="/i/:type" element={<Home config={configuration} apiURL={apiURL} />} />
        <Route path="/ciudadanos" element={<Ciudadanos config={configuration} apiURL={apiURL} />} />
        <Route path="/ciudadanos/:id" element={<Ciudadanos config={configuration} apiURL={apiURL} />} />
        <Route path="/ciudadanos/:id/:type" element={<Ciudadanos config={configuration} apiURL={apiURL} />} />
        
        <Route path="/combustible" element={<Combustible config={configuration} apiURL={apiURL} />} />
        <Route path="/combustible/:id" element={<Combustible config={configuration} apiURL={apiURL} />} />
        <Route path="/combustible/:id/:type" element={<Combustible config={configuration} apiURL={apiURL} />} />
        
        <Route path="/compras" element={<Compras config={configuration} apiURL={apiURL} />} />
        <Route path="/compras/:id" element={<Compras config={configuration} apiURL={apiURL} />} />
        <Route path="/compras/:id/:type" element={<Compras config={configuration} apiURL={apiURL} />} />
        
        <Route path="/rentas" element={<Rentas config={configuration} apiURL={apiURL} />} />
        <Route path="/rentas/:id" element={<Rentas config={configuration} apiURL={apiURL} />} />

        <Route path="/comercios" element={<Comercios config={configuration} apiURL={apiURL} />} />
        <Route path="/comercios/:id" element={<Comercios config={configuration} apiURL={apiURL} />} />
        <Route path="/comercios/:id/:type" element={<Comercios config={configuration} apiURL={apiURL} />} />
        
        <Route path="/hacienda" element={<Hacienda config={configuration} apiURL={apiURL} />} />
        <Route path="/hacienda/:id" element={<Hacienda config={configuration} apiURL={apiURL} />} />

        
        <Route path="/usuarios" element={<Usuarios config={configuration} apiURL={apiURL} />} />
        <Route path="/usuarios/:id" element={<Usuarios config={configuration} apiURL={apiURL} />} />

        <Route path="/sms" element={<SMSSender config={configuration} apiURL={apiURL} />} />

        <Route path="/roles" element={<Groups config={configuration} apiURL={apiURL} />} />
        <Route path="/roles/:id" element={<RolesOverview config={configuration} apiURL={apiURL} />} />

        {/* PDF GENERATOR */}
        <Route path="/pdf/combustible" element={<PDFCombustible config={configuration} apiURL={apiURL} />} />
        <Route path="/pdf/compra" element={<PDFCompra config={configuration} apiURL={apiURL} />} />
        <Route path="/pdf/rentas" element={<PDFRentas config={configuration} apiURL={apiURL} />} />
        <Route path="/pdf/hacienda" element={<PDFHacienda config={configuration} apiURL={apiURL} />} />

        {/* SCANNERS */}
        <Route path="/scanner/documentos" element={<SCDocumentos config={configuration} apiURL={apiURL} />} />
        <Route path="/scanner/pdf417" element={<PDF417 config={configuration} apiURL={apiURL} />} />

        {/* CAMERA */}
        <Route path="/camera/documentos" element={<CADocumentos config={configuration} apiURL={apiURL} />} />
        <Route path="/camera/obtener" element={<CAObtener config={configuration} apiURL={apiURL} />} />
        <Route path="/camera/skeleton" element={<CASkeleton config={configuration} apiURL={apiURL} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const [config, setConfig] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [apiURL, setApiURL] = useState("/api.php");
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);
  const location = useLocation();
  const [padding, setPadding] = useState({
    left: '265px',
    right: '10px',
  });
  
  const useColor = Cookies.get('design-color');
  if (useColor) {
    document.documentElement.setAttribute('data-dbb-style', useColor);
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://devbybit.com/demos/tablerio/dist/js/tabler.min.js';
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map((popoverTriggerEl) => {
      return new window.bootstrap.Popover(popoverTriggerEl); 
    });
  });

  useEffect(() => {
    const verifyKey = Cookies.get('jwt');
    const fetchData = async () => {
      try {
        let LicenseKeyData;
        if (!verifyKey) {
          const licenseResponse = await axios.post(apiURL, {
            type: 'jwt',
            user: false,
            permissions: []
          });
          LicenseKeyData = licenseResponse.data.jwt;
          Cookies.set('jwt', LicenseKeyData, { expires: 1 / 24 });
        } else {
          LicenseKeyData = Cookies.get('jwt');
        }
        const configResponse = await axios.post(apiURL, {
          type: '/devbybit.com/configuration/file',
        }, {
          headers: {
            Authorization: `Bearer ${LicenseKeyData}`,
          },
        });
        const configData = configResponse.data;
        setConfig(configData);
        setApiURL(configData.uri + '/api.php');
      } catch (error) {
        setConfig([]);
      } finally {
        setIsLoader(false);
      }
    };
  
    fetchData();
  }, []);
  


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setPadding({ left: '10px', right: '10px' });
      } else if (window.innerWidth < 1100) {
        setPadding({ left: '75px', right: '10px' });
      } else {
        setPadding({ left: isSidebarClosed ? '75px' : '265px', right: '10px' });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSidebarClosed]);
  

  if (!isLoader) {
    //window.onload = async () => {
    //  if (!contents.valid) {
    //    return <Licenseunknown />;
    //  }
    //}
  } else {
    return (
      <div className="container container-slim py-4 mt-5">
        <div className="text-center">
          <div className="mb-3">
            <a href={`https://munity.com.ar`} className="navbar-brand rounded" style={{ borderRadius: '7px' }}><img src="/static/img/logo.jpg" height="64" alt="Munity Software" /></a>
          </div>
          <div class="text-secondary mb-3">Iniciando Software</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }
  
  if (!config) {
    return 'Error in configuration!';
  }

  if (config.status == 1) {
    return <Maintenance config={config} apiURL={apiURL} maintenance={config.maintenance} />
  }


  return (
    <>
      <UserProvider config={config} apiURL={apiURL}>
        <PermissionsProvider>
          
          <HelmetProvider>
            <Helmet>
                <meta name="title" content={config.name} />
                <meta name="author" content="Vuhp & DevByBit Team" />
                <meta
                  name="description"
                  content="Plataforma digital que facilita el acceso a servicios, información y participación ciudadana. Es una herramienta útil para los residentes de la localidad, ya que les permite realizar trámites de manera más eficiente, mantenerse informados sobre eventos y noticias locales, y participar activamente en el desarrollo de su comunidad."
                />

                <meta name="keywords" content="plataforma digital ciudadana, servicios municipales en línea, participación ciudadana, trámites en línea, información municipal, noticias locales, eventos comunitarios, acceso a servicios municipales, gestión municipal digital, comunicación con el gobierno local, participación comunitaria, trámites municipales eficientes, portal de la ciudad, comunidad y desarrollo local, herramientas para residentes, plataforma de servicios públicos, transparencia gubernamental, portal ciudadano, colaboración ciudadana en línea, tecnología para municipios" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={config.uri} />
                <meta property="og:title" content={config.name} />
                <meta
                  property="og:description"
                  content="Plataforma digital que facilita el acceso a servicios, información y participación ciudadana. Es una herramienta útil para los residentes de la localidad, ya que les permite realizar trámites de manera más eficiente, mantenerse informados sobre eventos y noticias locales, y participar activamente en el desarrollo de su comunidad."
                />
                <meta property="og:image" content={config.logo} />
                <meta property="twitter:url" content={config.uri} />
                <meta property="twitter:title" content={config.name} />
                <meta
                  property="twitter:description"
                  content="Plataforma digital que facilita el acceso a servicios, información y participación ciudadana. Es una herramienta útil para los residentes de la localidad, ya que les permite realizar trámites de manera más eficiente, mantenerse informados sobre eventos y noticias locales, y participar activamente en el desarrollo de su comunidad."
                />
                <meta property="twitter:image" content={config.logo} />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <Header config={config} apiURL={apiURL} isSidebarClosed={isSidebarClosed} setIsSidebarClosed={setIsSidebarClosed} />
            <div className={`${location.pathname.includes('/camera') ? 'page-wrappers' : 'page-wrappers mt-6'}`} style={{ 
              paddingLeft: location.pathname.includes('/camera') ? '0' : padding.left, 
              paddingRight: location.pathname.includes('/camera') ? '0' : padding.right 
            }}>
              <div className='container-fluid'>
                <AnimatedRoutes configuration={config} apiURL={apiURL} />
              </div>
            </div>
            <div className="page-wrapper-footer" style={{ 
              paddingLeft: location.pathname.includes('/camera') ? '0' : padding.left, 
              paddingRight: location.pathname.includes('/camera') ? '0' : padding.right 
            }}>
              <Footer />
            </div>
          </HelmetProvider>
        </PermissionsProvider>
      </UserProvider>
    </>
  );
};

export default App;
