import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import axios from 'axios';
import Cookies from 'js-cookie'; 
import IMask from 'imask';
import Swal from 'sweetalert2';
import { UserContext } from '../../components/UserProvider.js';
import { PermissionsContext } from '../../components/hasPermissions.js';

const Content = ({ config, apiURL, data, setPayment, handleCheckboxChange }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);
  const [steps, setSteps] = useState(1);
  const [ciudadanos, setCiudadanos] = useState([]);
  const [opciones, setOpciones] = useState([]);
  const [newResult, setNewResult] = useState([]);
  const [inputDni, setInputDni] = useState('');
  const [expireUse, setExpireUse] = useState(false);
  const [amountService, setAmountService] = useState(0);

  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useState(true);
  const [stepIsLoader, setStepIsLoader] = useState(true);

  const [select, setSelect] = useState([]);
  
  const currentYear = new Date().getFullYear();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async () => {
    try {
        const jwt = Cookies.get('jwt');
        const idselect = Cookies.get('rentas_id_select');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/rentas',
            option: '/list/search',
            id: idselect
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const datas = configResponse.data;
        if (datas && datas.result) {
          setSelect(datas.result);
          setAmountService(datas.result.amount);
        } else {
          setSelect([]);
        }
    } catch (error) {
        setSelect([]);
    } finally {
      fetchOptions();
    }
  }

  const fetchOptions = async () => {
    try {
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/rentas',
            option: '/servicios',
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const datas = configResponse.data;
        if (datas && Array.isArray(datas.result)) {
          setOpciones(datas.result);
        } else {
          setOpciones([]);
        }
    } catch (error) {
        setOpciones([]);
    } finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const jwt = Cookies.get('jwt');
    const idselect = Cookies.get('rentas_id_select');
    
    if (!hasPermission('m.rentas.modificar')) {
      return null;
    }

    const formData = {
      id: idselect,
      observaciones: e.target['observaciones'].value,
      monto: e.target['monto'].value,
      services: e.target['services'].value,
      creator: userLogged.id,
    };

    try {
      const response = await axios.post(apiURL, {
        type: '/munity/rentas',
        option: '/editar',
        ...formData,
      }, { headers: { Authorization: `Bearer ${jwt}`, }, });
  
      const result = response.data;
      if (!result.valid) {
        Toast.fire({ icon: 'error', title: result.message });
        return;
      }
      Toast.fire({ icon: 'success', title: result.message });
      Cookies.remove('rentas_list');
      navigate(-1);
    } catch (error) {
      Toast.fire({ icon: 'error', title: 'Error en la accion.' });
    } finally {
      setStepIsLoader(false);
    }
  };


  return (
    <>
<form id="agregar_vale_combustible" method="POST" className="row" onSubmit={(e) => handleSubmit(e)}>
    {isLoader ? (
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="mb-3">
            <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div class="text-secondary mb-3">Preparando formulario</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    ) : (
      <>
    <div className="mb-3">
      <label className="form-label required" htmlFor="combustible">Servicios</label>
      <div className="form-selectgroup">
        {opciones.map((opcion) => {
            const isUsed = opcion.nombre == select.service ? true : false;
            return (
            <label className="form-selectgroup-item" key={opcion.id}>
                <input type="radio" name="services" defaultValue={opcion.nombre} className="form-selectgroup-input" defaultChecked={isUsed} onChange={() => setAmountService(opcion.monto)} />
                <span className="form-selectgroup-label">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
                    {opcion.nombre}
                </span>
            </label>
            );
        })}
        
      </div>
    </div>

    <label htmlFor="litros" className="col-sm-12 form-label">Costo del Servicio</label>
    <div class="input-group mb-0">
      <span class="input-group-text">
        $
      </span>
      <input type="text" class="form-control" name="monto" placeholder="0" autocomplete="off" value={amountService} readOnly />
    </div>
    <p className='text-secondary'>El costo por el servicio es aplicado al seleccionar el servicio y no puede ser modificado.</p>
    
    <div className="mb-3">
      <label className="form-label required" htmlFor="observaciones">Observaciones:</label>
      <div>
        <div className="input-icon mb-0">
          <span className="input-icon-addon">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-file"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /></svg>
          </span>
          <textarea className="form-control" placeholder="Descripcion de la orden." name="observaciones" id="observaciones" required={true}>{select.reason}</textarea>
        </div>
        <small className="form-hint pt-0" />
      </div>
    </div>

        <button type="sumit" className='btn btn-warning'>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-device-floppy me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
            Guardar Cambios
        </button>
      </>
    )}
    
</form>


    </>
  );
};

export default Content
