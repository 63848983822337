import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { parse, format, isThisYear } from 'date-fns';
import { es } from 'date-fns/locale';
import Swal from 'sweetalert2';

import { PermissionsContext } from '../../components/hasPermissions.js';
import UserStats from './chart/Users.js'
import RelativeTime from '../../components/RelativeTime.js';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const location = useLocation();
  const { id, type } = useParams();
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map((popoverTriggerEl) => {
      return new window.bootstrap.Popover(popoverTriggerEl); 
    });
  });

  
  const fetchData = async () => {
    try {
      const jwt = Cookies.get('jwt');
      const response = await axios.post(apiURL, {
        type: '/munity/usuarios',
        option: '/principal',
      }, { headers: { Authorization: `Bearer ${jwt}` } });

      const initialData = response.data;

      if (initialData && initialData.result) {
        setData(initialData.result[0]);
        console.log(initialData.result);
      } else {
        console.error("No hay datos en la respuesta de la API.", initialData);
      }
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, []);

  return (
    <>
      <div className='row row-cards'>
<div className="col-md-6 col-xl-4">
  <div className="card card-sm">
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col-auto">
          <span className="bg-theme text-white avatar">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-users"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
          </span>
        </div>
        <div className="col">
          <div className="font-weight-medium">
            Registrados
          </div>
          <div className="text-secondary">
            Hay {data.total ?? 0} usuarios.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="col-md-6 col-xl-4">
  <div className="card card-sm">
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col-auto">
          <span className="bg-theme text-white avatar">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-stars"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17.8 19.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" /><path d="M6.2 19.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" /><path d="M12 9.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" /></svg>
          </span>
        </div>
        <div className="col">
          <div className="font-weight-medium">
            Staff's
          </div>
          <div className="text-secondary">
            Hay {data.staff ?? 0} staffs.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="col-md-6 col-xl-4">
  <div className="card card-sm">
    <div className="card-body">
      <div className="row align-items-center">
        <div className="col-auto">
          <span className="bg-theme text-white avatar">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-scan"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7v-1a2 2 0 0 1 2 -2h2" /><path d="M4 17v1a2 2 0 0 0 2 2h2" /><path d="M16 4h2a2 2 0 0 1 2 2v1" /><path d="M16 20h2a2 2 0 0 0 2 -2v-1" /><path d="M5 12l14 0" /></svg>
          </span>
        </div>
        <div className="col">
          <div className="font-weight-medium">
            Registros sin validar
            <span className="form-help ms-2" data-bs-toggle="popover" data-bs-placement="top" 
            data-bs-content="<p>Usuarios registrados en el sitio validado automaticamente, Se requiere una validacion por una persona real para autenticar cualquier error. El usuario podra seguir utilizando el sistema pero dentro de los 30 dias su cuenta sera suspendida por invalidacion por persona real.</p><p class='mb-0'><a href='#'>Todos los usuarios con el estado 'VALIDAR' solicitara la validacion.</a></p>" 
            data-bs-html="true">?</span>
          </div>
          <div className="text-secondary">
            Hay {data.invalid ?? 0} usuarios sin validar.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
      <div className='row row-cards'>
        <div className="col-md-12 col-xl-8">
            <div className='card'>
                <UserStats config={config} apiURL={apiURL} />
            </div>
        </div>
        <div className="col-md-12 col-xl-4">
            <div className='card'>
                <div className='card-header'>
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user me-2 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                    Usuarios mas Recientes.
                </div>
                <div className='card-body'>
                    {data.cuentas?.length ? (
                        data.cuentas?.map((item) => {

                            return (
                                <div className="row g-3 align-items-center mb-2" key={item.id}>
                                    <Link to={`/usuarios/${item.id}`} className="col-auto">
                                        <span className="avatar" style={{backgroundImage: `url(${config.uri + item.avatar})`}}>
                                        <span className="badge bg-red" /></span>
                                    </Link>
                                    <div className="col text-truncate">
                                        <Link to={`/usuarios/${item.id}`} className="text-reset d-block text-truncate">{item.name}</Link>
                                        <div className="text-secondary text-truncate mt-n1">Hace <RelativeTime date={item.since} /></div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <>No hay usuarios recientes.</>
                    )}

                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Content
