import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Principal from './home/Principal';
import Analitica from './home/intendente/Analitica.js';
import Autorizacion from './home/intendente/Autorizacion.js';
import Suspencion from './home/intendente/Suspencion.js';
import Areas from './home/Areas.js';
import { UserContext } from '../components/UserProvider.js';
import { PermissionsContext } from '../components/hasPermissions.js';

const Content = ({ config, apiURL }) => {
  const { type } = useParams();
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);

  return (
    <div className="mt-5">
      {hasPermission('m.intendente') && (
      <ul className="nav nav-bordered mb-4">
        <li className="nav-item">
          <Link className={`nav-link${!type ? " active" : ""}`} aria-current="page" to="/">Principal</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link${type && type == 'analitica' ? " active" : ""}`} to="/i/analitica">Analítica</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link${type && type == 'autorizacion' ? " active" : ""}`} to="/i/autorizacion">Autorización</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link${type && type == 'suspencion' ? " active" : ""}`} to="/i/suspencion">Suspencion</Link>
        </li>
      </ul>
      )}
      {hasPermission('m.intendente') ? (
        type == 'analitica' ? (
          <Analitica config={config} apiURL={apiURL} />
        ) : type == 'autorizacion' ? (
          <Autorizacion config={config} apiURL={apiURL} />
        ) : type == 'suspencion' ? (
          <Suspencion config={config} apiURL={apiURL} />
        ) : (
          <Principal config={config} apiURL={apiURL} />
        )
      ) : (
        <Areas config={config} apiURL={apiURL} />
      )}
      
    </div>
  );
};

export default Content
