import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UserContext } from '../components/UserProvider.js';
import DropdownUser from '../components/UserMenuDropdown.js';
import { IconUserCircle } from '@tabler/icons-react';
import '@tabler/core/dist/js/tabler.min.js';
import Cookies from 'js-cookie';
import { PermissionsContext } from '../components/hasPermissions.js';

const Content = ({ config, apiURL, isSidebarClosed, setIsSidebarClosed }) => {
  const { userLogged, logoutUser } = useContext(UserContext);
  const { hasPermission } = useContext(PermissionsContext);
  const location = useLocation();
  const arrowRefs = useRef([]);

  const toggleMenu = (index) => {
    arrowRefs.current[index].classList.toggle("showMenu");
  };

  const toggleSidebar = (e) => {
    e.preventDefault();
    setIsSidebarClosed(!isSidebarClosed ?? true);
  };
  return (
    <>
      <nav className={`navbar ${location.pathname.includes('/camera') && "camera-option"}`}>
        <div className="logo-details">
        <a href="/" className="logo-details">
          <img src={config.logo} width={32} alt="DevByBit" />
          <span className="logo_name font-22">{config.name}</span>
        </a>
        <div style={{ borderLeft: '1px solid #ccc', marginLeft: '10px', paddingLeft: '10px' }}>
          <a href="/" className="btn-action text-primary" onClick={(e) => toggleSidebar(e)}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-menu-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 6l16 0" /><path d="M4 12l16 0" /><path d="M4 18l16 0" /></svg>
          </a>
        </div>
        </div>
        <div className="nav-links d-none d-sm-inline-block">
          <ul>
            <li>
              <a href="?theme=light" className="btn-action hide-theme-light">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-sun"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
              </a>
              <a href="?theme=dark" className="btn-action hide-theme-dark">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-moon"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" /></svg>
              </a>
            </li>
          </ul>
        </div>
      </nav>
<div className={`sidebar ${isSidebarClosed ? "close" : ""} ${location.pathname.includes('/camera') && "camera-option"}`}>
  <div className="logo-details">
    <img src={`https://license.devbybit.com/parent/img/icon.png`} width={32} alt="DevByBit" />
    <span className="logo_name font-24">DevByBit</span>
  </div>
  <ul className="nav-links">
    <li className={`${(location.pathname === '/' || location.pathname.includes('/i/')) && "active"}`}>
      <Link to="/">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-layout-dashboard icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1" /><path d="M5 16h4a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1" /><path d="M15 12h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1" /><path d="M15 4h4a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1" /></svg>
        <span className="link_name">Panel</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/">Panel</Link></li>
      </ul>
    </li>
    {hasPermission('m.ciudadanos') && (
    <li className={`${location.pathname.includes('/ciudadanos') && "active"}`} ref={(el) => (arrowRefs.current['ciudadanos'] = el)}>
        <div class="iocn-link" onClick={() => toggleMenu('ciudadanos')}>
          <Link to="/ciudadanos">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user-square-rounded icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 13a3 3 0 1 0 0 -6a3 3 0 0 0 0 6z" /><path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" /><path d="M6 20.05v-.05a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v.05" /></svg>
            <span class="link_name">Ciudadanos</span>
          </Link>
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down arrow"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
        </div>
        <ul class="sub-menu">
          <li><Link class="link_name" to="/ciudadanos">Ciudadanos</Link></li>
          <li><Link to="/ciudadanos/agregar">Agregar Ciudadano</Link></li>
        </ul>
    </li>
    )}
    {hasPermission('m.compras') && (
    <li className={`${location.pathname.includes('/compras') && "active"}`} ref={(el) => (arrowRefs.current['compras'] = el)}>
        <div class="iocn-link" onClick={() => toggleMenu('compras')}>
          <Link to="/compras">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-shopping-bag icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z" /><path d="M9 11v-5a3 3 0 0 1 6 0v5" /></svg>
            <span class="link_name">Compras</span>
          </Link>
          {hasPermission('m.compras.agregar') && (
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down arrow"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
          )}
        </div>
        
        <ul class="sub-menu">
          <li><Link class="link_name" to="/compras">Compras</Link></li>
          {hasPermission('m.compras.agregar') && (<li><Link to="/compras/agregar">Agregar Compras</Link></li>)}
        </ul>
    </li>
    )}
  {hasPermission('m.combustible') && (
    <li className={`${location.pathname.includes('/combustible') && !location.pathname.includes('/hacienda/combustible') && "active"}`} ref={(el) => (arrowRefs.current['combustible'] = el)}>
        <div class="iocn-link" onClick={() => toggleMenu('combustible')}>
          <Link to="/combustible">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-gas-station icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3" /><path d="M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14" /><path d="M3 20l12 0" /><path d="M18 7v1a1 1 0 0 0 1 1h1" /><path d="M4 11l10 0" /></svg>
            <span class="link_name">Combustible</span>
          </Link>
          {(hasPermission('m.combustible.precios') || hasPermission('m.combustible.precios')) && (
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down arrow"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
          )}
          </div>
        <ul class="sub-menu">
          <li><Link class="link_name" to="/combustible">Combustible</Link></li>
          {hasPermission('m.combustible.precios') && (<li><Link to="/combustible/precios">Precios</Link></li>)}
          {hasPermission('m.combustible.agregar') && (<li><Link to="/combustible/agregar">Nueva Orden</Link></li>)}
        </ul>
    </li>
  )}


  {hasPermission('m.rentas') && (
    <li className={`${location.pathname.includes('/rentas') && "active"}`}>
      <Link to="/rentas">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-building-bank icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 21l18 0" /><path d="M3 10l18 0" /><path d="M5 6l7 -3l7 3" /><path d="M4 10l0 11" /><path d="M20 10l0 11" /><path d="M8 14l0 3" /><path d="M12 14l0 3" /><path d="M16 14l0 3" /></svg>
        <span className="link_name">Rentas</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/rentas">Rentas</Link></li>
      </ul>
    </li>
  )}
  {hasPermission('m.hacienda') && (
    <li className={`${location.pathname.includes('/hacienda') && "active"}`}>
      <Link to="/hacienda">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-tax icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8.487 21h7.026a4 4 0 0 0 3.808 -5.224l-1.706 -5.306a5 5 0 0 0 -4.76 -3.47h-1.71a5 5 0 0 0 -4.76 3.47l-1.706 5.306a4 4 0 0 0 3.808 5.224" /><path d="M15 3q -1 4 -3 4t -3 -4z" /><path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" /><path d="M12 10v1" /><path d="M12 17v1" /></svg>
        <span className="link_name">Hacienda</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/hacienda">Hacienda</Link></li>
      </ul>
    </li>
  )}
  

  {hasPermission('m.roles') && (
    <li className={`${location.pathname.includes('/roles') && "active"}`} ref={(el) => (arrowRefs.current['roles'] = el)}>
      <Link to="/roles">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-stack-pop icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 9.5l-3 1.5l8 4l8 -4l-3 -1.5" /><path d="M4 15l8 4l8 -4" /><path d="M12 11v-7" /><path d="M9 7l3 -3l3 3" /></svg>
        <span className="link_name">Roles</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/roles">Roles</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.usuarios') && (
    <li className={`${location.pathname.includes('/usuarios') && "active"}`} ref={(el) => (arrowRefs.current['usuarios'] = el)}>
        <div class="iocn-link" onClick={() => toggleMenu('usuarios')}>
          <Link to="/usuarios">
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-users icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
            <span class="link_name">Usuarios</span>
          </Link>
        </div>
        <ul class="sub-menu">
          <li><Link class="link_name" to="/usuarios">Usuarios</Link></li>
        </ul>
    </li>
  )}



  {hasPermission('m.juzgado-faltas') && (
    <li className={`${location.pathname.includes('/juzgado-de-faltas') && "active"}`}>
      <Link to="/juzgado-de-faltas">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-old icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11 21l-1 -4l-2 -3v-6" /><path d="M5 14l-1 -3l4 -3l3 2l3 .5" /><path d="M8 4m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M7 17l-2 4" /><path d="M16 21v-8.5a1.5 1.5 0 0 1 3 0v.5" /></svg>
        <span className="link_name">Juzgado de faltas</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/juzgado-de-faltas">Juzgado de faltas</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.comercios') && (
    <li className={`${location.pathname.includes('/comercios') && "active"}`}>
      <Link to="/comercios">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-building-store icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 21l18 0" /><path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" /><path d="M5 21l0 -10.15" /><path d="M19 21l0 -10.15" /><path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" /></svg>
        <span className="link_name">Comercios</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/comercios">Comercios</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.facturas') && (
    <li className={`${location.pathname.includes('/facturas') && "active"}`}>
      <Link to="/facturas">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-file-invoice icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M9 7l1 0" /><path d="M9 13l6 0" /><path d="M13 17l2 0" /></svg>
        <span className="link_name">Facturas</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/facturas">Facturas</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.multas') && (
    <li className={`${location.pathname.includes('/multas') && "active"}`}>
      <Link to="/multas">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-businessplan icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16 6m-5 0a5 3 0 1 0 10 0a5 3 0 1 0 -10 0" /><path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4" /><path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4" /><path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4" /><path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" /><path d="M5 15v1m0 -8v1" /></svg>
        <span className="link_name">Multas</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/multas">Multas</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.reclamos') && (
    <li className={`${location.pathname.includes('/reclamos') && "active"}`}>
      <Link to="/reclamos">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user-exclamation icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4c.348 0 .686 .045 1.008 .128" /><path d="M19 16v3" /><path d="M19 22v.01" /></svg>
        <span className="link_name">Reclamos</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/reclamos">Reclamos</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.bromatologia') && (
    <li className={`${location.pathname.includes('/bromatologia') && "active"}`}>
      <Link to="/bromatologia">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-recharging icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7.038 4.5a9 9 0 0 0 -2.495 2.47" /><path d="M3.186 10.209a9 9 0 0 0 0 3.508" /><path d="M4.5 16.962a9 9 0 0 0 2.47 2.495" /><path d="M10.209 20.814a9 9 0 0 0 3.5 0" /><path d="M16.962 19.5a9 9 0 0 0 2.495 -2.47" /><path d="M20.814 13.791a9 9 0 0 0 0 -3.508" /><path d="M19.5 7.038a9 9 0 0 0 -2.47 -2.495" /><path d="M13.791 3.186a9 9 0 0 0 -3.508 -.02" /><path d="M12 8l-2 4h4l-2 4" /><path d="M12 21a9 9 0 0 0 0 -18" /></svg>
        <span className="link_name">Bromatologia</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/bromatologia">Bromatologia</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.transito') && (
    <li className={`${location.pathname.includes('/transito') && "active"}`}>
      <Link to="/transito">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-bus-stop icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" /><path d="M18 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M10 5h7c2.761 0 5 3.134 5 7v5h-2" /><path d="M16 17h-8" /><path d="M16 5l1.5 7h4.5" /><path d="M9.5 10h7.5" /><path d="M12 5v5" /><path d="M5 9v11" /></svg>
        <span className="link_name">Transito</span>
      </Link>
      <ul className="sub-menu blank">
        <li><Link className="link_name" to="/transito">Transito</Link></li>
      </ul>
    </li>
  )}

  {hasPermission('m.anuncios') && (
    <li className={`${location.pathname.includes('/anuncios') && "active"}`} ref={(el) => (arrowRefs.current['anuncios'] = el)}>
        <div class="iocn-link" onClick={() => toggleMenu('anuncios')}>
          <Link to="/anuncios">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-speakerphone icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 8a3 3 0 0 1 0 6" /><path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5" /><path d="M12 8h0l4.524 -3.77a.9 .9 0 0 1 1.476 .692v12.156a.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8" /></svg>
            <span class="link_name">Anuncios</span>
          </Link>
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down arrow"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
        </div>
        <ul class="sub-menu">
          <li><Link class="link_name" to="/anuncios">Anuncios</Link></li>
          <li><Link to="/anuncios/agregar">Agregar Anuncios</Link></li>
        </ul>
    </li>
  )}
  {hasPermission('m.vehiculos') && (
    <li className={`${location.pathname.includes('/vehiculos') && "active"}`} ref={(el) => (arrowRefs.current['vehiculos'] = el)}>
        <div class="iocn-link" onClick={() => toggleMenu('vehiculos')}>
          <Link to="/vehiculos">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-car icon-dbb"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" /></svg>
            <span class="link_name">Vehiculos</span>
          </Link>
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down arrow"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
        </div>
        <ul class="sub-menu">
          <li><Link class="link_name" to="/vehiculos">Vehiculos</Link></li>
          <li><Link to="/vehiculos/agregar">Agregar Vehiculos</Link></li>
        </ul>
    </li>
  )}
    

    <li className=''>
      <DropdownUser config={config} apiURL={apiURL} />
    </li>


  </ul>
</div>


    </>
  );
};

export default Content
