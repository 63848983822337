import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import axios from 'axios';
import Cookies from 'js-cookie'; 
import IMask from 'imask';
import Swal from 'sweetalert2';
import { UserContext } from '../../components/UserProvider.js';
import { PermissionsContext } from '../../components/hasPermissions.js';
import Options from './Opciones.js';
import Articulos from './Articulos.js';

const Content = ({ config, apiURL, data }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);
  const [steps, setSteps] = useState(1);
  const [ciudadanos, setCiudadanos] = useState([]);
  const [opciones, setOpciones] = useState([]);
  const [newResult, setNewResult] = useState([]);
  const [inputDni, setInputDni] = useState('');
  const [expireUse, setExpireUse] = useState(false);
  const [carUse, setCarUse] = useState(false);

  const [arts, setArts] = useState([]);

  const [isLoader, setIsLoader] = useState(true);
  const [stepIsLoader, setStepIsLoader] = useState(true);
  
  const currentYear = new Date().getFullYear();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async () => {
    try {
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/compras',
            option: '/buscador',
            search: inputDni
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const configData = configResponse.data;
        setCiudadanos(configData.result);
    } catch (error) {
        console.error('Error fetching data:', error);
        setCiudadanos([]);
    }
  }

  const fetchOptions = async () => {
    try {
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/compras',
            option: '/opciones',
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const configData = configResponse.data;
        setOpciones(configData);
    } catch (error) {
        setOpciones([]);
    } finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchData();
    if (inputDni.length >= 8) { // Ejecuta fetchData solo si el DNI tiene al menos 8 dígitos
      const delayDebounce = setTimeout(() => {
        fetchData();
      }, 1000); // 1 segundo de espera

      return () => clearTimeout(delayDebounce); // Limpia el temporizador si hay cambios
    }
  }, [inputDni, data]);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const jwt = Cookies.get('jwt');
    
    if (!hasPermission('m.compras.agregar')) {
      return null;
    }

    const formData = {
      dni: e.target['dni'].value,
      tipo: e.target['tipo'].value,
      obs: e.target['observaciones'].value,
      expire: e.target['expire'].value,
      arts: arts,
      comercio: e.target['comercio'].value,
      creator: userLogged.id,
    };

    try {
      const response = await axios.post(apiURL, {
        type: '/munity/compras',
        option: '/crear',
        ...formData,
      }, { headers: { Authorization: `Bearer ${jwt}`, }, });
  
      const result = response.data;
      if (!result.valid) {
        Toast.fire({ icon: 'error', title: result.message });
        return;
      }
      Cookies.remove('compras_list');
      Toast.fire({ icon: 'success', title: result.message });
      setNewResult(result.result);
    } catch (error) {
      Toast.fire({ icon: 'error', title: 'Error en la accion.' });
    } finally {
      setStepIsLoader(false);
    }
  };

  return (
    <>
<form id="agregar_vale_combustible" method="POST" className="row" onSubmit={(e) => handleSubmit(e)}>
  <div className="d-md-none">
    <ul class="steps steps-green steps-counter mb-4">
      <li class={`step-item${steps == 1 ? ' active' : ''}`}></li>
      <li class={`step-item${steps == 2 ? ' active' : ''}`}></li>
      <li class={`step-item${steps == 3 ? ' active' : ''}`}></li>
      
    </ul>
      <div className={`${steps == 1 ? 'hidden' : ''}`}>
        <button type="button" className="btn btn-danger me-2" onClick={() => setSteps(steps == 2 ? 1 : 2)}>
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
          Anterior
        </button>

        <button type={arts.length > 0 ? 'submit' : 'button'} className={`btn btn-primary ${steps == 3 ? '' : 'hidden'} ${arts.length > 0 ? '' : 'disabled'}`}>
          Generar Orden
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-forward-up ms-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 14l4 -4l-4 -4" /><path d="M19 10h-11a4 4 0 1 0 0 8h1" /></svg>
        </button>

        <button type="button" className={`btn btn-primary ${steps == 3 ? 'hidden' : ''}`} onClick={() => setSteps(3)}>
          Continuar
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-forward-up ms-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 14l4 -4l-4 -4" /><path d="M19 10h-11a4 4 0 1 0 0 8h1" /></svg>
        </button>
      </div>
    </div>
    <div className="col-md-3 col-lg-3 d-none d-md-inline-block">
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Pasos</h3>
          <ul className="steps steps-vertical">
            <li className={`step-item${steps == 1 ? ' active' : ''}`}>
              <div className="h4 m-0">Ciudadano</div>
              <div className="text-secondary">Elija el ciudadano para continuar.</div>
            </li>
            <li className={`step-item${steps == 2 ? ' active' : ''}`}>
              <div className="h4 m-0">Detalles de la Orden</div>
              <div className="text-secondary">Toda la informacion requerida para la orden.</div>
            </li>
            <li className={`step-item${steps == 3 ? ' active' : ''}`}>
              <div className="h4 m-0">Final - Articulos</div>
              <div className="text-secondary">Agregar todos los articulos necesarios.</div>
            </li>
          </ul>
        </div>
      </div>
      <div className={`${steps == 1 ? 'hidden' : ''}`}>
        <button type="button" className="btn btn-danger me-2" onClick={() => setSteps(steps == 2 ? 1 : 2)}>
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
          Anterior
        </button>

        <button type={arts.length > 0 ? 'submit' : 'button'} className={`btn btn-primary ${steps == 3 ? '' : 'hidden'} ${arts.length > 0 ? '' : 'disabled'}`}>
          Generar Orden
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-forward-up ms-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 14l4 -4l-4 -4" /><path d="M19 10h-11a4 4 0 1 0 0 8h1" /></svg>
        </button>

        <button type="button" className={`btn btn-primary ${steps == 3 ? 'hidden' : ''}`} onClick={() => setSteps(3)}>
          Continuar
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-forward-up ms-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 14l4 -4l-4 -4" /><path d="M19 10h-11a4 4 0 1 0 0 8h1" /></svg>
        </button>
      </div>
    </div>

  <div className={`col-md-9 col-sm-12${steps == 1 ? '' : ' hidden'}`}>
    <label htmlFor="dni" className="form-label required">DNI:</label>
    <div className="g-2 mb-3">
      <div className="input-icon">
        <span className="input-icon-addon">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
        </span>
        
        <input
        type="text"
        className="form-control"
        name="dni"
        placeholder="000000000"
        pattern="\d{7,9}"
        minLength="7"
        maxLength="9"
        value={inputDni}
        onInput={(e) => {
          e.target.value = e.target.value.slice(0, 9);
          setInputDni(e.target.value);
        }}
        />
      </div>
      <p className="text-secondary">¡Escribe el DNI del ciudadano, Y por ultimo presiona el ciudadano abajo!</p>
    </div>

<div className="card">
  <div className="card-header">
    <h3 className="card-title">Ciudadanos</h3>
  </div>
  <div className="list-group list-group-flush overflow-auto" style={{maxHeight: '35rem'}}>
  {Array.isArray(ciudadanos) && ciudadanos.length > 0 ? (
          ciudadanos.map((item, index) => {
            const isValid = inputDni === item.dni ? 'active' : '';
            return (
              <div key={index} className={`list-group-item ${isValid}`} onClick={() => { setInputDni(item.dni); setSteps(2); fetchOptions(); }}>
                <div className="row">
                  <div className="col text-truncate">
                    <div className="text-body d-block">{item.nombre}</div>
                    <div className="text-secondary text-truncate mt-n1">DNI: {item.dni}</div>
                  </div>
                </div>
              </div>
            );
          })) : (
            <div className={`list-group-item`}>
                <div className="row">
                  <div className="col text-truncate">
                    <div className="text-body d-block">No se encontro ningun ciudadano.</div>
                    <div className="text-secondary text-truncate mt-n1">Porfavor escriba otro DNI.</div>
                  </div>
                </div>
            </div>
          )}
    

  </div>
</div>


  </div>

  <div className={`col-md-9 col-sm-12${steps == 2 ? '' : ' hidden'}`}>
    {isLoader ? (
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="mb-3">
            <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div class="text-secondary mb-3">Preparando formulario</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    ) : (
      <>

    <div className="mb-3">
      <label className="form-label required" htmlFor="tipo">Tipos</label>
      <div className="form-selectgroup">
        <label className="form-selectgroup-item">
          <input type="radio" name="tipo" defaultValue="Bienes de Consumo" className="form-selectgroup-input" defaultChecked />
          <span className="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
            Bienes de Consumo
          </span>
        </label>
        <label className="form-selectgroup-item">
          <input type="radio" name="tipo" defaultValue="Transferencia" className="form-selectgroup-input" />
          <span className="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
            Transferencia
          </span>
        </label>
        <label className="form-selectgroup-item">
          <input type="radio" name="tipo" defaultValue="Ayuda" className="form-selectgroup-input" />
          <span className="form-selectgroup-label">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
            Ayuda
          </span>
        </label>
      </div>
    </div>

    <div className="mb-3">
      <label className="form-label required" htmlFor="observaciones">Observaciones:</label>
      <div>
        <div className="input-icon mb-0">
          <span className="input-icon-addon">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-file"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /></svg>
          </span>
          <textarea className="form-control" placeholder="Descripcion de la orden." name="observaciones" id="observaciones" defaultValue={""} required={true} />
        </div>
        <small className="form-hint pt-0" />
      </div>
    </div>


    <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column mb-2">
      <label className="form-selectgroup-item flex-fill">
        <input type="checkbox" name="s-vencimiento" defaultValue="visa" className="form-selectgroup-input" onChange={(e) => setExpireUse(e.target.checked)} />
        <div className="form-selectgroup-label d-flex align-items-center p-3">
          <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-stats me-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" /><path d="M18 14v4h4" /><path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M15 3v4" /><path d="M7 3v4" /><path d="M3 11h16" /></svg>
            <strong>Vencimiento</strong>
          </div>
        </div>
      </label>
    </div>

    <div className={`mb-3${expireUse ? '' : ' hidden'}`}>
      <label className="form-label required" htmlFor="expiration">Vencimiento</label>
      <div>
        <div className="row g-2">
          <div className="col input-icon">
            <span className="input-icon-addon">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-stats" style={{marginLeft: 10}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" /><path d="M18 14v4h4" /><path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M15 3v4" /><path d="M7 3v4" /><path d="M3 11h16" /></svg>
            </span>
            <input type="date" className="form-control" placeholder={30} name="expire" id="expire" />
          </div>
        </div>
        <small className="form-hint pt-0">Si decide colocar el vencimiento, No ponga una fecha mas de 1-2 Meses, Si no queria habilitar esta opcion desabilitela volviendo a presionar 'Vencimiento'.</small>
      </div>
    </div>

    <div className="mb-3">
      <label className="form-label required" htmlFor="combustible">Comercios</label>
      <div className="form-selectgroup">
      <Options opciones={opciones} />
        
      </div>
    </div>
    

      </>
    )}
    
  </div>

  


  <div className={`col-md-9 col-sm-12${steps == 3 ? '' : ' hidden'}`}>
    <Articulos arts={arts} setArts={setArts} />
  </div>
  
</form>


    </>
  );
};

export default Content
