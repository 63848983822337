import React from "react";

const RelativeTime = ({ date }) => {
    const calculateRelativeTime = (inputDate) => {
        const now = new Date();
        const then = new Date(inputDate);
        const diff = Math.abs(now - then); // Diferencia en milisegundos

        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (seconds < 60) return `${seconds} ${seconds === 1 ? "segundo" : "segundos"}`;
        if (minutes < 60) return `${minutes} ${minutes === 1 ? "minuto" : "minutos"}`;
        if (hours < 24) return `${hours} ${hours === 1 ? "hora" : "horas"}`;
        if (days < 30) return `${days} ${days === 1 ? "día" : "días"}`;
        if (months < 12) return `${months} ${months === 1 ? "mes" : "meses"}`;
        return `${years} ${years === 1 ? "año" : "años"}`;
    };

    return <>{calculateRelativeTime(date)}</>;
};

export default RelativeTime;
