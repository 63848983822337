import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import Cookies from 'js-cookie';

const UserStats = ({config, apiURL}) => {
  const [data, setData] = useState([]);
  const [groupBy, setGroupBy] = useState('day');
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const jwt = Cookies.get('jwt');
      const response = await axios.post(apiURL, {
        type: '/munity/chart',
        option: '/stats/users',
        group_by: groupBy
      }, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });

      const result = response.data;
      if (result.valid) {
        setData(result.data);
        setLoading(false);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [groupBy]);

  const chartOptions = {
    chart: {
      type: 'area',
      zoom: {
        enabled: true
      }
    },
    xaxis: {
      categories: data.map(item => item.period),
      title: { text: 'Periodo' }
    },
    yaxis: {
      title: { text: 'Cantidad de Usuarios' }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    legend: {
      position: 'top'
    }
  };

  const chartSeries = [
    {
      name: 'Validados',
      data: data.map(item => item.validated)
    },
    {
      name: 'Invalidados',
      data: data.map(item => item.invalidated)
    }
  ];

  return (
    <div className="card-body">
      <div className="mb-3">
        <select 
          value={groupBy} 
          onChange={(e) => setGroupBy(e.target.value)} 
          className="form-select"
        >
          <option value="day">Día</option>
          <option value="month">Mes</option>
          <option value="year">Año</option>
        </select>
      </div>
      {loading ? (
        <p>Cargando estadisticas...</p>
      ) : (
        <ApexCharts 
          options={chartOptions} 
          series={chartSeries} 
          type="area" 
          height={350} 
        />
      )}
    </div>
  );
};

export default UserStats;
