import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { UserContext } from '../../../components/UserProvider.js';
import { PermissionsContext } from '../../../components/hasPermissions.js';

import Combustible from './autorizacion/Combustible.js';
import Compras from './autorizacion/Compra.js';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('q');

  const [ countComb, setCountComb ] = useState(0);
  const [ countComp, setCountComp ] = useState(0);
  const [ isLoader, setIsLoading ] = useState(true);
  
  const [ autorizacion, setAutorizacion ] = useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async () => {
    const jwt = Cookies.get('jwt');
    try {
        const ciudadanosRes = await axios.post(apiURL, {
            type: '/munity/general',
            option: '/listed'
        }, {
            headers: { Authorization: `Bearer ${jwt}` }
        });
        const ciudadanosResData = ciudadanosRes.data;
        setCountComb(ciudadanosResData.combustible);
        setCountComp(ciudadanosResData.compras);

        fetchAutor();
    } catch (error) {
        setCountComb(0);
        setCountComp(0);
    } finally {
        setIsLoading(false);
    }
  };

  
  const fetchAutor = async () => {
    const jwt = Cookies.get('jwt');
    try {
        const ciudadanosRes = await axios.post(apiURL, {
            type: '/munity/general',
            option: '/automatizacion/verify'
        }, {
            headers: { Authorization: `Bearer ${jwt}` }
        });
        const ciudadanosResData = ciudadanosRes.data;
        setAutorizacion(ciudadanosResData.exist);
    } catch (error) {
        setAutorizacion(0);
    }
  };

  useEffect(() => {
      fetchData();
      fetchAutor();
  }, []);

  
  const generateEgreso = async (e) => {
    e.preventDefault();
      const jwt = Cookies.get('jwt');
      const rawFecha = e.target['fecha']?.value || '';

      const formData = {
        fecha: rawFecha,
        creator: userLogged.id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/general',
          option: '/automatizacion',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;
        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        fetchData();
        fetchAutor();
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
  };

  if (isLoader) {
    return (
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="mb-3">
            <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div class="text-secondary mb-3">Preparando interfaz</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">
        <div className="alert alert-success bg-theme" role="alert">
            <div className="d-flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                </div>
                <div>
                    <h4 className="alert-title">Nueva funcion para autorizar automaticamente!</h4>
                    <div className="text-secondary">Puedes habilitarlo y desactivarlo cuando quieras, Incluso poner una fecha limite. ¿Quieres empezar? 
                        <a href="#" className="text-primary ms-2" data-bs-toggle="modal" data-bs-target="#automatizacion">¡Persiona aqui!</a></div>
                </div>
            </div>
        </div>



        <div className="row row-cards">
            <div className="col-sm-12 col-md-6">
                <Link to="/i/autorizacion" className="card card-borderless">
                    {!searchQuery && (<div class="card-status-start bg-primary" />)}
                    <div className="card-body">
                        <h3 className="card-title">Ordenes de Combustible</h3>
                        <div>Hay {countComb} ordenes pendiente</div>
                    </div>
                </Link>
            </div>
            <div className="col-sm-12 col-md-6">
                <Link to="/i/autorizacion?q=compras" className="card card-borderless">
                    {searchQuery == 'compras' && (<div class="card-status-start bg-primary" />)}
                    <div className="card-body">
                        <h3 className="card-title">Ordenes de Compras</h3>
                        <div>Hay {countComp} ordenes pendiente</div>
                    </div>
                </Link>
            </div>
        </div>

        {searchQuery == 'compras' ? (
            <Compras config={config} apiURL={apiURL} countComp={countComp} setCountComp={setCountComp} />
        ) : (
            <Combustible config={config} apiURL={apiURL} countComb={countComb} setCountComb={setCountComb} />
        )}


        
<div className="modal modal-blur fade" id="automatizacion" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <form className="modal-content" method="POST" onSubmit={(e) => generateEgreso(e)} style={{background: 'transparent', boxShadow: 'none'}}>
              <div className='card mb-3'>
                <div class={`card-status-bottom bg-red`} />
                <div className="modal-body text-center py-4">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon text-red icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                  <h3>Autorizacion automaticas</h3>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header">
                  <h5 className="modal-title">Automatizacion de autorizado.</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="card-body">
                  {autorizacion ? (
                    <>
                      <input type="hidden" className='form-control' value="" placeholder='3000' name="fecha" />
                      Ya hay una autorizacion automatica, Puedes cancelarla.
                    </>
                  ) : (
                    <>
                      <span className='mb-2 required'>Fecha limite</span>
                      <input type="datetime-local" className='form-control' placeholder='3000' name="fecha" required />
                    </>
                  )}
                </div>
              </div>
              
              <div className="d-flex align-items-center mb-3">
                {autorizacion ? (
                  <button type="submit" className='btn btn-danger w-100' data-bs-dismiss="modal">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-credit-card-pay me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" /><path d="M3 10h18" /><path d="M16 19h6" /><path d="M19 16l3 3l-3 3" /><path d="M7.005 15h.005" /><path d="M11 15h2" /></svg>
                    Cancelar
                  </button>
                ) : (
                  <button type="submit" className='btn btn-primary w-100' data-bs-dismiss="modal">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-credit-card-pay me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" /><path d="M3 10h18" /><path d="M16 19h6" /><path d="M19 16l3 3l-3 3" /><path d="M7.005 15h.005" /><path d="M11 15h2" /></svg>
                    Confirmar
                  </button>
                )}
              </div>
              <div className="alert alert-info bg-theme" role="alert">
                <div className="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 9h.01" /><path d="M11 12h1v4h1" /></svg>
                  </div>
                  <div>
                    <h4 className="alert-title">Nota</h4>
                    <div className="text-secondary">Si ya se encuentra una automatizacion activa, Simplemente dale a 'cancelar' y si estas por crear uno nuevo, Simplemente indica una fecha limite, Esa fecha sera cuando finalizara de autorizar automaticamente.</div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>

    </div>
  );
};

export default Content
